@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$padding-mobile: 40px;

.modal {
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  outline: none;
  padding: 40px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;

  @include max-screen(767px) {
    padding: 0;
  }

  &.saveDesignModal {
    display: flex;
  }
}

.overlay {
  position: relative;
  z-index: 101;

  &:before {
    background: $charcoal;
    opacity: 30%;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    min-height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &.lightOverlay {
    &:before {
      background: rgba(55, 55, 55, 0.6);
    }
  }
}

.panel {
  @include fadeIn(200ms);
  background-color: $white;
  border: none;
  box-shadow: none;
  max-width: 544px;
  min-height: 500px;
  margin: auto;
  overflow: hidden;

  @include max-screen(767px) {
    max-width: 100%;
    min-height: 100%;
  }

  &.saveDesignModal {
    width: 343px;
    min-height: 460px;
  }
}

.header {
  @include subheader-font-demi;
  background-color: $mx-color-primary;
  color: $white;
  text-align: center;
  padding: 25px 85px;
  position: relative;

  @include max-screen(767px) {
    padding-left: 30px;
    padding-right: 75px;
  }
}

.closeButton {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    display: block;
    width: 30px;
    height: 30px;
  }

  path {
    stroke: $white;
    stroke-width: 3;
  }
}

.content {
  padding: 30px 85px;
  color: $charcoal;

  @include max-screen(767px) {
    padding: 30px;
  }
}

.intro {
  @include body-font-2;
  max-width: 370px;

  a {
    color: $charcoal;
  }
}

.headerImageLayout {
  padding: 65px 20px;

  @include max-screen(400px) {
    padding: 20px 15px;
  }

  .panel {
    margin: auto;
    max-width: 544px;

    @include max-screen(767px) {
      max-width: 370px;
      min-height: auto;
    }

    @include mobile(landscape) {
      height: auto;
    }
  }

  .header {
    background: none;
    height: auto !important;
    padding: 0;

    .headerImage {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      height: 190px;
      justify-content: center;
      overflow: hidden;
      position: relative;
    }

    .closeButton {
      right: 10px;
      top: 10px;
      transform: none;
      height: 32px;
      width: 32px;

      svg {
        height: 32px;
        width: 32px;
      }

      path {
        stroke: $black;
      }
    }

    @include max-screen(400px) {
      height: 145px;
    }
  }

  .headerText {
    @include mx-font-subheading-2;
    background: transparent;
    color: $ash;
    letter-spacing: normal;
    padding: 30px 30px 20px;
    text-align: left;
    text-transform: none;

    @include max-screen(400px) {
      padding: 30px 20px 20px;
    }
  }

  .content {
    padding: 0 30px 30px;

    @include max-screen(400px) {
      padding: 0 20px 30px;
    }
  }
}

.mediaLayout {
  &.modal {
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 40px 10%;
    right: 0;
    top: 0;
    transform: none;

    @include max-screen(992px) {
      padding: 0 65px;
    }

    @include max-screen(767px) {
      padding: 0;
    }
  }

  .panel {
    height: auto;
    max-width: 100%;

    @include max-screen(1024px) {
      min-height: 100%;
    }
  }

  .header {
    @include subheader-font-demi;
    text-transform: none;
    background: $white;
    color: $charcoal;
    padding: 20px 0 0 40px;
    text-align: left;

    @include max-screen(992px) {
      display: flex;
      align-items: center;
      padding: 30px 0 0 30px;
    }

    @include max-screen(767px) {
      padding: 30px 50px 0 10px;
    }
  }

  .closeButton {
    cursor: pointer;
    box-sizing: content-box;
    height: 50px;
    transform: none;
    right: 30px;
    width: 50px;
    top: 70%;
    z-index: 2;

    svg {
      height: 50px;
      width: 50px;
    }

    path {
      stroke: $black;
      stroke-width: 2;
    }

    @include max-screen(992px) {
      right: 20px;
      top: 20px;
    }

    @include max-screen(767px) {
      right: 0;
      top: 18px;

      svg {
        height: 37px;
      }
    }
  }

  .content {
    padding: 0;
  }
}
